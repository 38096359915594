<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import printInvoice from '@/components/print-invoice'

export default {
  page: {
    title: "Booking Order Created",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup (props) {
    const inv_id = props.inv_id
    const createInvoice = () => {
      printInvoice(inv_id)
    }
    return {
      createInvoice
    }
  },
  props: {
    inv_id : String
  },
  data() {
    return {
      title: "Booking Order Created",
      items: []
    };
  },
  computed: {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
              <b-alert show variant="success">
                Your order has been created successfully.<br/>
                Please pay the amount of your total invoice and then contact our billing officer. <br/>
                Your order will be automatically canceled if payment has not yet been fully processed within 1 hour.
                <!-- if payment has not been received
                Silahkan lakukan pembayaran sesuai dari total nilai tertera pada invoice.
                Pesanan anda akan otomatis dibatalkan jika pembayaran belum masuk dalam waktu 1 jam-->
              </b-alert>
              <br/>
              <b-button id="invprint" variant="success" @click="createInvoice">
                <i class="mdi mdi-file-download-outline align-middle"></i>
                Download Invoice
              </b-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>